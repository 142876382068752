export const MERCHANTS = [
  {
    imgLogo: require('src/images/campaign-travel-spring/logos/campaign-travel-spring-logo-1.png'),
    destinationURL: 'https://travel.willer.co.jp/',
    category: [1, 5],
  },
  {
    imgLogo: require('src/images/campaign-travel-spring/logos/campaign-travel-spring-logo-2.png'),
    destinationURL: 'https://www.asoview.com/',
    category: [3],
  },
  {
    imgLogo: require('src/images/campaign-travel-spring/logos/campaign-travel-spring-logo-3.png'),
    destinationURL: 'https://www.airtrip.jp/',
    category: [2],
  },
  {
    imgLogo: require('src/images/campaign-travel-spring/logos/campaign-travel-spring-logo-4.png'),
    destinationURL: 'https://www.spacemarket.com/',
    category: [6],
  },
  {
    imgLogo: require('src/images/campaign-travel-spring/logos/campaign-travel-spring-logo-5.png'),
    destinationURL: 'https://www.kosokubus.com/',
    category: [1],
  },
  {
    imgLogo: require('src/images/campaign-travel-spring/logos/campaign-travel-spring-logo-6.png'),
    destinationURL: 'https://www.sakuratravel.jp/',
    category: [2],
  },
  {
    imgLogo: require('src/images/campaign-travel-spring/logos/campaign-travel-spring-logo-7.png'),
    destinationURL: 'https://www.sorahapi.jp?med_id=paidy',
    category: [2],
  },
  {
    imgLogo: require('src/images/campaign-travel-spring/logos/campaign-travel-spring-logo-8.png'),
    destinationURL: 'https://www.489.fm/',
    category: [1],
  },
  {
    imgLogo: require('src/images/campaign-travel-spring/logos/campaign-travel-spring-logo-9.png'),
    destinationURL: 'https://vipliner.biz/',
    category: [1],
  },
  {
    imgLogo: require('src/images/campaign-travel-spring/logos/campaign-travel-spring-logo-10.png'),
    destinationURL: 'https://www.busnoru.jp/',
    category: [1],
  },
  {
    imgLogo: require('src/images/campaign-travel-spring/logos/campaign-travel-spring-logo-11.png'),
    destinationURL: 'https://domhotel.airtrip.jp/',
    category: [4],
  },
  {
    imgLogo: require('src/images/campaign-travel-spring/logos/campaign-travel-spring-logo-12.png'),
    destinationURL: 'https://www.milkyway-ex.com/',
    category: [1],
  },
  {
    imgLogo: require('src/images/campaign-travel-spring/logos/campaign-travel-spring-logo-13.png'),
    destinationURL: 'https://www.wbf.co.jp/',
    category: [5],
  },
  {
    imgLogo: require('src/images/campaign-travel-spring/logos/campaign-travel-spring-logo-14.png'),
    destinationURL: 'https://travelist.jp/',
    category: [2],
  },
  {
    imgLogo: require('src/images/campaign-travel-spring/logos/campaign-travel-spring-logo-15.png'),
    destinationURL: 'https://activityjapan.com/',
    category: [3],
  },
  {
    imgLogo: require('src/images/campaign-travel-spring/logos/campaign-travel-spring-logo-16.png'),
    destinationURL: 'https://tabiraku.travel/tour/',
    category: [4],
  },
  {
    imgLogo: require('src/images/campaign-travel-spring/logos/campaign-travel-spring-logo-17.png'),
    destinationURL: 'https://www.best1-ai-neural-trip.com/bustour/',
    category: [1],
  },
  {
    imgLogo: require('src/images/campaign-travel-spring/logos/campaign-travel-spring-logo-18.png'),
    destinationURL: 'https://oki-raku.net/activity/',
    category: [3, 4],
  },
];

export const TAGS = [
  { value: 0, label: 'すべて見る' },
  { value: 1, label: 'バス' },
  { value: 2, label: '航空券' },
  { value: 3, label: '遊び体験・チケット' },
  { value: 4, label: 'ホテル' },
  { value: 5, label: '国内ツアー	' },
  { value: 6, label: 'レンタルスペース' },
];
