import React from 'react';
import Layout from 'src/components/Layout/Layout';
import { Hidden } from '@material-ui/core';
import 'src/components/Layout/Layout.scss';
import { SITE_METADATA } from 'src/constants';
import SectionCampaignCondition from 'src/components/Merchant/Campaign/TravelSpring/SectionCampaignCondition';
import SectionMerchantGrid from 'src/components/Merchant/Campaign/TravelSpring/SectionMerchantGrid';
import SectionCampaignFooter from 'src/components/Merchant/Campaign/TravelSpring/SectionCampaignFooter';
import PaymentSteps from 'src/components/Merchant/Campaign/TravelSpring/PaymentSteps';
import styles from 'src/components/Merchant/Campaign/TravelSpring/TravelSpring.module.scss';

const TravelSpring = () => {
  return (
    <Layout
      SEOProps={{
        title: SITE_METADATA.merchantCampaignTravelSrping.title,
        description: SITE_METADATA.merchantCampaignTravelSrping.description,
      }}
      hasNoHeader
      hasNoFooter
    >
      <div className={styles.topBannerBackground}>
        <div className={styles.topBanner} />
      </div>
      <Hidden xsDown>
        <div className={styles.backgroundDescription}>
          <div className={styles.description} />
        </div>
      </Hidden>

      <PaymentSteps />
      <SectionCampaignCondition />
      <div className={styles.merchantTitle}>
        <p>おでかけ応援キャッシュバック</p>
        <p className={styles.pinkText}>対象ショップ</p>
      </div>
      <SectionMerchantGrid />
      <SectionCampaignFooter />
    </Layout>
  );
};

export default TravelSpring;
