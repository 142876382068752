import React from 'react';
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import styles from './SectionCampaignFooter.module.scss';

const CampaignFooter = () => {
  return (
    <div className={styles.campaignFooter}>
      <p className={styles.campaignFooterText}>
        ペイディについて、わからないこと、不安なことは
        <a
          href="https://cs.paidy.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          こちらから
        </a>
        お問い合わせください。
      </p>
      <Link to="/">
        <p className={styles.campaignFooterLink}>paidy.com</p>
      </Link>
      <p className={styles.campaignFooterMark}>© Paidy Inc.</p>
      <OutboundLink
        href="https://twitter.com/paidyjp"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={require('../../../../images/icon-x-black.svg')}
          alt="Paidy | Twitter"
        />
      </OutboundLink>
    </div>
  );
};

export default CampaignFooter;
