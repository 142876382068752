import React from 'react';
import Steps from 'src/components/Apple/Steps';
import styles from './PaymentSteps.module.scss';

const STEPS = [
  {
    image: require('src/images/landing/step_1_a.svg'),
    info: `ショップのお支払い方法で「あと払い（ペイディ）」を選択して、メアドと携帯番号を入力。`,
  },
  {
    image: require('src/images/home-steps-2.svg'),
    info: 'SMSで送信される4桁の認証コードを入力してお買い物完了。',
  },
  {
    image: require('src/images/landing/step_3.png'),
    info: '翌月1日～3日の間にメールとSMSでご請求金額をお知らせ。',
  },
  {
    image: require('src/images/home-steps-4.svg'),
    info:
      '翌月10日までに、コンビニ払い、銀行振込、口座振替でお支払いください。',
  },
];

export default function PaymentSteps() {
  return (
    <section>
      <p className={styles.headline}>
        ペイディの使い方はとってもかんたん。
        <br />
        行きたいところに今すぐ行こう！
      </p>
      <Steps
        steps={STEPS}
        containerStyle={{ paddingBottom: 80 }}
        gap={20}
        columnWidth={264}
        imageWidth={184}
      />
    </section>
  );
}
