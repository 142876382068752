import React from 'react';
import BoxIndex from '../../../BoxIndex/BoxIndex';
import styles from './SectionCampaignCondition.module.scss';

const CAMPAIGN_TITLE_CONTENTS = [
  {
    title: <strong>キャンペーン概要</strong>,
    content: (
      <>
        キャンペーン期間中に対象ショップの決済画面でペイディの翌月あと払いもしくは
        <a href="/landing/plus_3pay/" target="_blank" rel="noopener noreferrer">
          3回あと払い
        </a>
        を利用すると、一店舗ごとにもれなく100円キャッシュバック。さらに抽選で、期間中の対象ショップでの最高額のお買い物について全額（最大10万円）キャッシュバック。
        <br />
        <br />
        ※キャンペーン期間中に上記条件でお買い物された方に、5月31日までにキャッシュバックを実施。5月31日ごろにペイディアプリおよびMyPaidyに反映されます。決済画面には反映されませんのでご注意ください。
      </>
    ),
  },
  {
    title: <strong>キャンペーン期間</strong>,
    content: <strong>2022年4月22日（金）0:00～5月8日（日）23:59</strong>,
  },
  {
    title: <strong>注意事項</strong>,
    content: (
      <>
        ・キャッシュバックは1加盟店につきお一人様1回までとさせていただきます。
        <br />
        ・全額キャッシュバックの当選は一人一回までで、該当加盟店内での期間中の最高額のお買い物。
        <br />
        ・4月中にお買い物をされた場合もキャッシュバックの反映は5月末頃となりますのでご了承ください。
        <br />
        ・株式会社Paidyは本キャンペーンを、キャンペーン期間であっても予告なく変更または終了する場合がございます。
        <br />
        ・不正と判断された場合や購入のキャンセルがあった場合は、キャッシュバックが取り消される場合がございます。
        <br />
        ・その他条件は
        <a
          href="https://terms.paidy.com/cashback/"
          target="_blank"
          rel="noopener noreferrer"
        >
          キャッシュバック利用規約
        </a>
        に準じます。
        <br />
        ・ペイディに関するお問い合わせは
        <a
          href="https://cs.paidy.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          ペイディヘルプページ
        </a>
        へご連絡ください。
      </>
    ),
  },
];

const SectionCampaignCondition = () => {
  return (
    <BoxIndex sectionStyle={{ maxWidth: 1230 }}>
      <div className={styles.campaignWrapper}>
        {CAMPAIGN_TITLE_CONTENTS.map((campaign, i) => (
          <div key={i} className={styles.campaign}>
            <div className={styles.title}>{campaign.title}</div>
            <div className={styles.content}>{campaign.content}</div>
          </div>
        ))}
      </div>
    </BoxIndex>
  );
};

export default SectionCampaignCondition;
